<template>
    <div>
        <breadcrumb
            name="Trade In"
            title="All Trade In Requests"
            :subtitle="'Request Of ' + request.user_name"
            @onTitleClicked="$emit('cancel')"
        />
        <div class="card">
            <vx-card title="User Info" class="mb-base">
                <vs-divider />
                <vs-row vs-type="flex">
                    <vs-col
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-lg="6"
                        vs-sm="12"
                    >
                        <div
                            class="vx-col-5 flex-1 m-auto"
                            id="account-info-col-1"
                        >
                            <table class="sm-table">
                                <tr>
                                    <td class="font-semibold p-4">User Name</td>
                                    <td class="p-4">{{ request.user_name }}</td>
                                </tr>
                                <tr>
                                    <td class="font-semibold p-4">
                                        User Mobile
                                    </td>
                                    <td class="p-4">
                                        {{ request.user_mobile }}
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </vs-col>
                    <vs-col
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-lg="6"
                        vs-sm="12"
                    >
                        <div
                            class="vx-col-5 flex-1 m-auto"
                            id="account-info-col-1"
                        >
                            <table class="sm-table">
                                <tr>
                                    <td class="font-semibold p-4">
                                        User Email
                                    </td>
                                    <td class="p-4">
                                        {{ request.user_email }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="font-semibold p-4">Send At</td>
                                    <td class="p-4">
                                        {{ request.created_at }}
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </vs-col>
                </vs-row>
            </vx-card>
            <vx-card title="Details" class="mb-base">
                <vs-divider />
                <vs-row vs-type="flex" class="flex">
                    <vs-col
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-lg="6"
                        vs-sm="12"
                    >
                        <div
                            class="vx-col-5 flex-1 m-auto"
                            id="account-info-col-1"
                        >
                            <h4 class="header">User's Car Info</h4>
                            <vs-row>
                                <vs-col vs-lg="4">
                                    <vs-divider />
                                </vs-col>
                            </vs-row>
                            <table class="sm-table">
                                <tr>
                                    <td class="font-semibold p-4">Car Brand</td>
                                    <td class="p-4">
                                        {{ request.user_car_brand_name_en }} /
                                        {{ request.user_car_brand_name_ar }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="font-semibold p-4">Car Model</td>
                                    <td class="p-4">
                                        {{ request.user_car_model_name_en }} /
                                        {{ request.user_car_model_name_ar }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="font-semibold p-4">
                                        Car Category
                                    </td>
                                    <td class="p-4">
                                        {{
                                            request.user_car_category_name ||
                                            "Guest User"
                                        }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="font-semibold p-4">
                                        Cat Model Year
                                    </td>
                                    <td class="p-4">
                                        {{ request.user_car_model_year }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="font-semibold p-4">
                                        Car Kilometers
                                    </td>
                                    <td class="p-4">
                                        {{
                                            request.user_car_kilo_meters + " KM"
                                        }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="font-semibold p-4">
                                        Warranty Status
                                    </td>
                                    <td class="p-4">
                                        {{
                                            request.user_car_warranty_status ==
                                            "1"
                                                ? "In Warranty"
                                                : "Not Covered"
                                        }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="font-semibold p-4">
                                        Body PaintChanged
                                    </td>
                                    <td class="p-4">
                                        {{
                                            request.user_car_body_changed == "1"
                                                ? "Yes"
                                                : "No"
                                        }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="font-semibold p-4">Status</td>
                                    <td class="p-4">
                                        <vs-chip
                                            :color="
                                                getAgentStatusColor(
                                                    request.agent_status
                                                        ? request.agent_status
                                                        : 'None'
                                                )
                                            "
                                            class="
                                                product-order-status
                                                text-center
                                            "
                                        >
                                            {{
                                                getAgentStatusState(
                                                    request.agent_status
                                                        ? request.agent_status
                                                        : "None"
                                                )
                                            }}
                                        </vs-chip>
                                    </td>
                                </tr>
                                <!-- <tr>
                                    <td class="font-semibold p-4">
                                        Agent Name
                                    </td>
                                    <td class="p-4">
                                        <span
                                            v-if="
                                                request.agent_status &&
                                                    request.agent_status.admin
                                            "
                                        >
                                            {{
                                                request.agent_status.admin.name
                                            }}
                                        </span>
                                        <vs-td v-else>None</vs-td>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="font-semibold p-4">
                                        Agent Email
                                    </td>
                                    <td class="p-4">
                                        <span
                                            v-if="
                                                request.agent_status &&
                                                    request.agent_status.admin
                                            "
                                        >
                                            {{
                                                request.agent_status.admin.email
                                            }}
                                        </span>
                                        <vs-td v-else>None</vs-td>
                                    </td>
                                </tr> -->
                            </table>
                        </div>
                    </vs-col>
                    <vs-col
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-lg="6"
                        vs-sm="12"
                    >
                        <div
                            class="vx-col-5 flex-1 m-auto"
                            id="account-info-col-1"
                        >
                            <h4 class="header">New Car's Info</h4>
                            <vs-row>
                                <vs-col vs-lg="4">
                                    <vs-divider />
                                </vs-col>
                            </vs-row>
                            <table class="sm-table">
                                <tr>
                                    <td class="font-semibold p-4">Car Brand</td>
                                    <td class="p-4">
                                        {{ request.target_car_brand_name_en }} /
                                        {{ request.target_car_brand_name_ar }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="font-semibold p-4">Car Model</td>
                                    <td class="p-4">
                                        {{ request.target_car_model_name_en }} /
                                        {{ request.target_car_model_name_ar }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="font-semibold p-4">
                                        Car Category
                                    </td>
                                    <td class="p-4">
                                        {{
                                            request.target_car_category_name_en
                                        }}
                                        /
                                        {{
                                            request.target_car_category_name_ar
                                        }}
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </vs-col>
                </vs-row>
            </vx-card>
            <vx-card title="User Car Images" class="mb-base">
                <vs-divider />
                <div class="mt-8 trade-in-images">
                    <div class="trade-in-images">
                        <div
                            class="image"
                            :style="`background-image: url('${image}')`"
                            @click="openImageInFullScreen(image)"
                            :key="index"
                            :src="image"
                            v-for="(image, index) in request.user_car_images"
                        />
                    </div>
                </div>
            </vx-card>
            <vx-card title="Update Status" class="mb-base">
                <vs-row vs-type="flex">
                    <vs-col
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-lg="12"
                        vs-sm="12"
                    >
                        <div
                            class="vx-col-5 flex-1 m-auto"
                            id="account-info-col-1"
                        >
                            <table>
                                <tr>
                                    <td class="font-semibold p-4">
                                        Request Status
                                    </td>
                                    <td class="p-4">
                                        <vs-chip
                                            :color="
                                                getAgentStatusColor(
                                                    request.agent_status
                                                        ? request.agent_status
                                                        : 'None'
                                                )
                                            "
                                            class="
                                                product-order-status
                                                text-center
                                            "
                                        >
                                            {{
                                                getAgentStatusState(
                                                    request.agent_status
                                                        ? request.agent_status
                                                        : "None"
                                                )
                                            }}
                                        </vs-chip>
                                        <feather-icon
                                            icon="EditIcon"
                                            svgClasses="w-6 mt-1 h-6 mr-2 hover:text-warning stroke-current"
                                            @click="
                                                agent_status_update =
                                                    !agent_status_update
                                            "
                                            class="ml-12"
                                        />
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </vs-col>
                    <vs-col
                        v-if="agent_status_update"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-lg="4"
                        vs-sm="4"
                    >
                        <label>Status</label>
                        <select v-model="agent_status">
                            <option value="pending">Pending</option>
                            <option value="in_progress">In Progress</option>
                            <option value="done">Done</option>
                        </select>
                    </vs-col>
                </vs-row>
                <div class="english" v-if="agent_status_update">
                  <small class="label">Agent Comment</small>
                  <ckeditor
                    :editor="editor"
                    v-model="agent_comment"
                    :config="editorConfigEn"
                    class="input"
                    v-if="!showHTML"
                  />
                  <textarea v-model="agent_comment" v-if="showHTML"></textarea>
                </div>
                <div class="optionBtns" v-if="agent_status_update">
                    <vs-button
                        class="mr-5 save"
                        icon-pack="feather"
                        icon="icon-save"
                        @click="changeAgentStatus"
                        >Save
                    </vs-button>
                    <vs-button
                        class="ml-5 cancel"
                        type="border"
                        color="primary"
                        @click="agent_status_update = false"
                        >Cancel
                    </vs-button>
                </div>
            </vx-card>
        </div>
        <vs-button
            color="primary"
            type="filled"
            style="float: right"
            @click="$emit('cancel')"
            >Back</vs-button
        >
        <div class="clr" />

        <vs-popup
            fullscreen
            title="Fullscreen Image"
            :active.sync="popupActive"
        >
            <center><img class="full-image" :src="image" alt="" /></center>
        </vs-popup>
    </div>
</template>

<script>
import { ChevronsRightIcon, HomeIcon } from "vue-feather-icons";
import Breadcrumb from "../../../components/general/breadcrumb";
import AgentStatusRow from "../../../components/table/rows/agent-status-row.vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
    name: "trade_in-item",
    components: { Breadcrumb, HomeIcon, ChevronsRightIcon, AgentStatusRow },
    props: {
        request: {
            required: true,
            default: {
                id: null,
                target_car_brand_name_en: "",
                target_car_brand_name_ar: "",
                target_car_model_name_en: "",
                target_car_model_name_ar: "",
                target_car_category_name_en: "",
                target_car_category_name_ar: "",
                user_car_brand_name_en: "",
                user_car_brand_name_ar: "",
                user_car_model_name_en: "",
                user_car_model_name_ar: "",
                user_car_category_name: "",
                user_car_model_year: null,
                user_car_kilo_meters: "",
                user_car_warranty_status: null,
                user_car_body_changed: null,
                user_name: "",
                user_mobile: "",
                user_email: "",
                created_at: "",
                agent_status: "",
                agent_comment: "",
            },
        },
    },
    data() {
        return {
            popupActive: false,
            image: "",
            agent_status: this.request.agent_status,
            agent_status_update: false,
            agent_comment: '',
            showHTML: false,
            editorConfigEn: {
              placeholder: "Agent Comment",
              language: "en",
            },
            editor: ClassicEditor,
        };
    },
    methods: {
        openImageInFullScreen(image) {
            this.popupActive = true;
            this.image = image;
        },
        changeAgentStatus() {
            const payload = {
                request_id: this.request.id,
                request_type: "trade_in",
                status: this.agent_status,
                agent_comment: this.agent_comment
            };
            console.log(this.$store.state.auth.token);
            this.$httpAdmin
                .post(`/requests/change-status`, payload, {
                    headers: {
                        Authorization: "Bearer " + this.$store.state.auth.token,
                    },
                })
                .then(() => {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: "Agent Status",
                        text: "Agent Status is changed successfully!",
                        color: "success",
                    });
                    location.reload();
                })
                .catch(() => {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: "Something Error",
                        text: "Couldn't fetch data from the server",
                        color: "danger",
                    });
                });
        },
        getAgentStatusColor(status) {
            if (status == "pending") return "dark";
            if (status == "in_progress") return "primary";
            if (status == "done") return "success";
            return "danger";
        },
        getAgentStatusState(status) {
            if (status == "pending") return "Pending";
            if (status == "in_progress") return "In Progress";
            if (status == "done") return "Done";
            return "Unknown";
        },
    },
    mounted() {
        console.log({ request: this.request });
    },
};
</script>

<style scoped lang="scss">
* {
    margin: 0;
    padding: 0;
}

.product-order-status {
    padding-right: 10px;
}
select {
    width: 90%;
    margin-left: 15px;
    padding: 12px;
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    color: rgba(0, 0, 0, 0.8);
}

.select {
    width: 25%;
    float: right;
    margin-bottom: 30px;
}

.card {
    margin-top: 30px;
}

.flex {
    display: flex;
    justify-content: center;
    align-items: baseline;
}

.main-component {
    cursor: pointer;
}

.name {
    font-weight: 900;
}

.header {
    margin: 20px 0px 20px 0px;
    color: grey;
}

.trade-in-images {
    max-height: 500px;
    overflow: auto;

    .image {
        height: 500px;
        width: 500px;
        background-position: 50%;
        border-radius: 10px;

        &:hover {
            opacity: 0.8;
            cursor: pointer;
            -webkit-transform: scale(0.9);
            transform: scale(0.9);
            -webkit-filter: blur(0px);
            filter: blur(0px);
            bottom: -10px;
        }
    }
}
.full-image {
    // width: 100%;
    // height: 100%;
    margin: auto;
    object-fit: contain;
}
</style>

<template>
    <div>
        <trade_in-list/>
    </div>
</template>

<script>
    import Trade_inList from './view-trade_in-list';

    export default {
        name: 'trade_in',
        components: { Trade_inList }
    };
</script>

<style scoped lang="scss">


</style>
